export enum ECivility {
  Monsieur,
  Madame,
  Autre,
  Société
}


export enum ECustomerType {
  Particulier,
  Professionnel
}


export enum EInputType {
  Text = 1,
  Number = 2,
  Date = 3,
  Time = 4,
  DateTime = 5,
  Email = 6,
  Password = 7,
  Checkbox = 8,
  Radio = 9,
  Select = 10,
  Textarea = 11,
  File = 12,
  Hidden = 13,
  Button = 14,
  Image = 15,
  Color = 16,
  Range = 17,
  Search = 18,
  Switch = 19,
  Slider = 20,
  Editor = 21,
  Tag = 22,
  Margin = 23,
  SelectCatalog = 24,
  Title = 25,
}

export enum EUnit {
  u = 'Unité',
  kg = 'Kg',
  m2 = 'M²',
  m3 = "M³",
  h = "h",
}
